.smallLogo {
    height: 40px;
}
.smallReturn {
    height:20px;
}
.refreshButton {
    height:20px;
}
.largeLogo {
    height: 55px;
}
.sloganLogo {
    height: 70px;
}
.splashLogo {
    height: 100px;
}
.navbarDecorator {
    height: 25px;
}