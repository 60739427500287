@keyframes fadeInExpand {
	0% {
		opacity: 0;
		transform: scale(0.95) translate(2%, 2%);
	}
	100% {
		opacity: 1;
		transform: scale(1) translate(0, 0);
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.fade-in-fastest {
	opacity: 1;
	animation-name: fadeInExpand;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}
.fade-in {
	opacity: 1;
	animation-name: fadeInExpand;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

.fade-in-fast {
	opacity: 1;
	animation-name: fadeInExpand;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
	height: 100%;
	width:100%
}

.fade-enter {
	opacity: 0;
  }
  .fade-enter-active {
	opacity: 1;
	transition: opacity 1s;
  }
  .fade-exit {
	opacity: 1;
  }
  .fade-exit-active {
	opacity: 0;
	transition: opacity 1s;
  }


  