

body {
  font-family: 'Roboto', sans-serif;  /* Apply the custom font globally */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.authenticator-background {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensures pseudo-element does not extend beyond parent */
}

.authenticator-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/loginBG.png'); /* Adjust the path as needed */
  background-size: cover;
  background-position: center;
  /*filter: blur(1px); /* Adjust the blur intensity as needed */
  z-index: -1; /* Ensures background remains behind content */
}
.data-amplify-container {
  border-radius: '5px';
  overflow: 'hidden';
}

.crosshair {
  position: absolute;
  top: calc(50% - 160px);
  left: 50%; /* 50% from the left of the container */
  width: 26px;
  height: 26px;
  /*background: url('path-to-crosshair-icon.png') no-repeat center;*/
  pointer-events: none; /* Prevent interaction interference */
  z-index: 1000; /* Ensures it's on top of the map */
  
  /* Translate by 50% of its own width and height to perfectly center it */
  transform: translate(-50%, -50%);
}

.amplify-card {
  background-color: var(--amplify-colors-background-secondary);
}
.amplify-card--outlined {
  border-color: var(--amplify-colors-black);
}
.amplify-heading--6 {
  color: var(--amplify-colors-secondary-80);
}
.amplify-text {
  color: var(--amplify-colors-neutral-80);
}

:root {
  --grey-hover: #dcdce6; /* This can be any color code you choose */
  --grey-hover-test: #dcdce6; /* This can be any color code you choose */
}

/* To use the color variable */
.element {
  color: var(--grey-hover);
}

.element {
  color: var(--grey-hover-test);
}

#map {
  background-color: rgb(99, 99, 99); /* or any color you prefer */
}
/*
.leaflet-container {

  background-color: var(--grey-hover) !important;
  
  }
*/

  .roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  